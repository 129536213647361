import { Alert, List, notification, Input } from "antd";
import "./comments.scss";
import React, { useEffect, useState } from "react";
import { deleteCommentFromServer } from "../../services/api-calls/comments.api";
// import {
//   deleteCommentById,
//   getCommentById,
//   getCommentsByDraftFile,
// } from "../../services/indexed-db/comments.indexeddb";
import VideoRecorder from "../video/video-recorder";
import { useLocation } from "react-router";
import { getCurrentTimeStamp, sortItemBasedOnCreation, waitForAWhile } from "../../services/config";
import AudioRecorder from "../audio-recorder";
// import { saveCommentAndSync } from "../../services/sync-methods/comments.sync";
import SmallSpinnerComponent from "../spinner/small-spinner.component";
import ViewComment from "./view-comment";
import { useDispatch, useSelector } from "react-redux";
// import {
//   deleteCommentFromSync,
//   saveCommentToSync,
// } from "../../services/indexed-db/sync.indexeddb";
// import { updateBooks } from "../../services/indexed-db/books.indexeddb";
import { toggleSyncStatus } from "../../redux/actions/util.action";
import axios from "axios";
import getHeader from "../../services/config";
import { draftFile } from "../../services/apis";
import { postCommentToServer } from "../../services/api-calls/comments.api"
const { TextArea } = Input;

const CommentsComponent = ({ chunk, type}) => {
  const location = useLocation();
  const [currentChunk, setCurrentChunk] = useState({});
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [isRecordingStarted, setIsRecordingStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [isSync,setIsSync] =useState(false)
  const [buttonHeight, setButtonHeight] = useState("54px");
  const [alertMessage, setAlertMessage] = useState({
    type: "",
    message: "",
  });

  const dispatch = useDispatch();
  const isRecording = useSelector((state) => state.recordingStatusReducer);
  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);
  const booksReducer = useSelector((state) => state.booksReducer);
  const [notificationApi, NotificationJSX] = notification.useNotification();
  const [count,setCount]=useState(0);
  const showNotification = (type, message) => {
    notification.destroy();
    notificationApi[type]({
      message,
      placement: "top",
    });
  };
  useEffect(() => {
    setCurrentChunk(chunk);
    loadComments();
  }, [setCurrentChunk, chunk,count]);

  useEffect(() => {
    setButtonHeight("54px");
  }, [type]);

  const loadComments = async (msg) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (msg) {
          showAlertMessage("success", msg);
        }
        setIsLoading(true);
        setCurrentChunk(chunk);
        try {
          let sortedComments
          // let data = await getCommentsByDraftFile(chunk?.fileId);
          if(location.pathname=="/to-do"){
            sortedComments = await sortItemBasedOnCreation(chunk.todoComments);
          }else{
            let data = await axios.post(
              `${draftFile.getDraftedFileByFileId}`,
              {fileId:chunk.fileId},
              getHeader()
            );
            sortedComments = await sortItemBasedOnCreation(data.data.comments);
          }
          
          setComments(sortedComments);
          setIsLoading(false);
          setIsRecordingStarted(false);
          resolve();
        } catch (error) {
          console.log("Error while loading comments ", error);
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (commentText.trim().length == 0) {
        showAlertMessage("error", "Please enter text");
      } else {
        let uniqueKey = await getCurrentTimeStamp();
        let commentObj = {
          role: localStorage.getItem("role"),
          fileId: uniqueKey,
          userId: localStorage.getItem("id"),
          user: `${localStorage.getItem("firstName")}  ${localStorage.getItem(
            "lastName"
          )} (${localStorage.getItem("role")})`,
          fileType: "text",
          comments: commentText.trim(),
          createdAt: new Date().toISOString(),
          projectName:location && location.state?location.state.project.projectName:null// only this field will not update in DB
        };

         

        if (navigator.onLine == true) {
          let commentRes = await postCommentToServer(currentChunk?.fileId, commentObj);
          // await saveCommentToIndexedDb(fileId, commentRes?.result);
          // resolve(true);
        }
        // await saveCommentAndSync(currentChunk?.fileName, currentChunk?.fileId, commentObj);
        // await updateChunkData(location.state.book);
        showAlertMessage("success", "Comment submitted successfully");
        setCommentText("");
        dispatch(toggleSyncStatus(true));
        setTimeout(() => {
          dispatch(toggleSyncStatus(false));
        }, 5000);
        // await reload();

        await loadComments();
      }
    } catch (error) {
      console.log(error);
      showAlertMessage("error", "Please try again ,after some time");
    }
    setIsLoading(false);
  };

  const updateChunkData = async (book) => {
    let chunk = location.state.chunk;
    if (chunk) {
      chunk.chunksDataProgress = "reviwed";
      let books = booksReducer.map((item) => {
        if (item.bookName == book.bookName) {
          comments?.length == 0 &&
            book.bookProgress.filter((item) => {
              item.review += 1;
            });

          comments.length == 0 &&
            book.chaptersProgress.filter((item) => {
              if (item.chapters == location.state.chapter) {
                item.review += 1;
              }
            });
          let chapter = book.chapters.filter((item) => {
            if (item.chapterName == location.state.chapter) {
              item.chunks.map((element) => {
                if (
                  element.chunkName === chunk.chunkName &&
                  element.chunksDataProgress != "reviwed"
                ) {
                  element = chunk;
                }
              });
            }
          });
          // await updateBooks(book.book_id, book);
        }
      })
    }
  };

  const updateProgress = async (book) => {
    let chunk = location.state.chunk;
    chunk.chunksDataProgress = "completed";

    let books = booksReducer.map((item) => {
      if (item.bookName == book.bookName) {
        book.bookProgress.filter((item) => {
          item.review -= 1;
        });

        book.chaptersProgress.filter((item) => {
          if (item.chapters == location.state.chapter) {
            item.review -= 1;
          }
        });
        let chapter = book.chapters.filter((item) => {
          if (item.chapterName == location.state.chapter) {
            item.chunks.map((element) => {
              if (
                element.chunkName === chunk.chunkName &&
                element.chunksDataProgress != "reviwed"
              ) {
                element = chunk;
              }
            });
          }
        });
        // await updateBooks(book.book_id, book);

      }
    })
  };

  const deleteComment = async (item) => {
    if (isRecordingStarted == true) {
      showAlertMessage("error", "Please stop recording");
    } else {
      setIsLoading(true);
      try {
        // fetch latest comment from indexeddb and save in local variable
        // let current_comment = await getCommentById(
        //   currentChunk.fileId,
        //   item.fileId
        // );
        // delete comment from indexed
        // await deleteCommentById(currentChunk.fileId, item.fileId);

        showAlertMessage("success", "Comment deleted successfully");

        // comment sync functions
        if (window.navigator.onLine == true) {
          try {
            // delete comment from server when comment is saved in main db
            await deleteCommentFromServer(`${currentChunk.fileId}`, item);

            // when user is on hybrid mode, then check particular comment from sync collection and remove it
            // await deleteCommentFromSync(
            //   `${currentChunk.fileId}`,
            //   item.fileId
            // );
          } catch (error) { }
        } else {
          // if (current_comment._id) {
          //   // delete comment which is synced with main db
          //   await saveCommentToSync(`${currentChunk.fileName}`,`${currentChunk.fileId}`, {
          //     type: "COMMENT_DELETE",
          //     comment: item,
          //   });
          // } else {
          //   // delete comment which is saved offline and in sync collection
          //   await deleteCommentFromSync(
          //     `${currentChunk.fileId}`,
          //     item.fileId
          //   );
          // }
        }
      } catch (error) {
        console.log(error);
      }
      await loadComments();
      comments.length <= 1 && updateProgress(location.state.book);
      setIsLoading(false);
    }
  };

  const showAlertMessage = async (type, message) => {
    setAlertMessage({
      ...alertMessage,
      type,
      message,
    });
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  const toggleRecording = () => {
    setIsRecordingStarted(!isRecordingStarted);
  };

  const handlePaginationHover = () => {
    if(isSync==false){
      setIsSync(true);
      if (isPlayingStatus == true) {
        showNotification("error", " Please pause current playing audio");
      }
      else if (isRecording == true) {
        showNotification("error", "Please stop recording");
      }

      setTimeout(() => {
        setIsSync(false)
      }, 5000);
    }
    
  }

  return (
    <>
      <h3 className="  ">Comment</h3>
      <div className="comments">
        <List
          loading={{
            spinning: isLoading,
            indicator: (
              <span>
                <SmallSpinnerComponent />
              </span>
            ),
          }}
          className="comment-list"
          itemLayout="horizontal"
          pagination={{
            pageSize: 3,
            // disabled: isPlayingStatus && isRecording,
            disabled: isPlayingStatus == true || isRecording == true ? true : false,
            itemRender: (page, type, originalElement) => {
              if (type === 'page') {
                // Conditionally render based on the isDisabled variable
                if (isPlayingStatus && isRecording) {
                  return (
                    <div style={{ display: 'inline-block', color: 'gray' }}>
                      {originalElement}
                    </div>
                  );
                } else {
                  return (
                    <div
                      onMouseEnter={handlePaginationHover}
                      style={{ display: 'inline-block' }}
                    >
                      {originalElement}
                    </div>
                  );
                }
              }
              return originalElement;
            },
            onChange: (page,pageSize) => {
              setCount(count+1)
            },
          }}
          dataSource={comments}
          renderItem={(item) => (
            <li>
              <ViewComment
                file={currentChunk}
                item={item}
                deleteComment={deleteComment}
                chunk={chunk}
                reload={loadComments}
              />
            </li>
          )}
        />

        <div className="pt-1">
          {showAlert && (
            <Alert
              message={alertMessage.message}
              type={alertMessage.type}
              showIcon
              className="w-86 my-1"
              closable
            />
          )}

          {
            location.pathname!="/to-do" ? 
            <div className="d-flex flex-row add-comment-section mb-4 w-86 form">
            <div className="d-flex items-center px-2 border-r-1">
              {location.state?.project.projectType.signLanguage == true ? (
                <>
                  {isLoading ? (
                    <SmallSpinnerComponent />
                  ) : (
                    <label
                      className="btn-primary"
                      onClick={(e) => {
                        setIsRecordingStarted(!isRecordingStarted);
                      }}
                    >
                      <VideoRecorder
                        chunk={chunk}
                        type="COMMENTFILE"
                        reload={loadComments}
                        setIsLoading={setIsLoading}
                        isDisabled={isLoading || isRecordingStarted}
                        targetType="comment"
                      />
                    </label>
                  )}
                </>
              ) : (
                <>
                  {isLoading ? (
                    <SmallSpinnerComponent />
                  ) : (
                    <span className="py-1">
                      <AudioRecorder
                        onClick={toggleRecording}
                        type="COMMENTFILE"
                        chunk={chunk}
                        reload={loadComments}
                        setIsLoading={setIsLoading}
                        targetType={"COMMENTFILE"}
                        showMessage={showAlertMessage}
                        setParentLoading={setIsLoading}
                      />
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="flex items-center w-full">
              <TextArea
                type="text"
                className="  "
                id="comment-textarea"
                style={{
                  resize: "none",
                  color: "black"
                }}
                placeholder="Add comment"
                rows={2}
                value={commentText}
                lang="zh-Hans"
                disabled={
                  isLoading ||
                  isRecordingStarted ||
                  isRecording ||
                  isPlayingStatus
                }
                onChange={(e) => {
                  setCommentText(e.target.value);
                }}
              ></TextArea>
              <button
                className="submit-btn btn"
                type="button"
                style={{ height: buttonHeight || "54px" }}
                disabled={
                  isLoading ||
                  isRecordingStarted ||
                  isRecording ||
                  isPlayingStatus
                }
                onClick={submitHandler}
              >
                Comment
              </button>
            </div>
            </div> :
            <></>
          }
        </div>
      </div>
      {NotificationJSX}
    </>
  );
};

export default CommentsComponent;
