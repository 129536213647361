import React from "react";
import FooterComponent from "../../components/ui/footer";
import HeaderUIComponent from "../../components/ui/header/header.ui";
import { Button, Layout, Table, Tooltip } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { files_api } from "../../services/apis";
import { useParams } from "react-router";
import SpinnerComponent from "../../components/spinner/spinner.component";
import SmallSpinnerComponent from "../../components/spinner/small-spinner.component";
import FilenameComponent from "../../components/ui/filename.component";
import AudioPlayer from "../../components/audio-player/audio-player.component";
import DraftScriptModal from "../../components/modal/draft-scirpt-modal";
import VideoPlayer from "../../components/video/video-player";
import CommentsComponent from "../../components/comments/comments.component";
import { useSelector } from "react-redux";
import { FaComment, FaCommentSlash } from "react-icons/fa";
import {
  removeUserDetailsFromLocalStorage,
  saveUserDetailsToLocalStorgae,
} from "../../services/save-user";

const SharedFiles = () => {
  const { id } = useParams();
  const [sharedFiles, setSharedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentData, setCommentData] = useState({});
  const [commnetObjId, setCommnetObjId] = useState("");

  const isPlayingStatus = useSelector((state) => state.playingStatusReducer);

  useEffect(() => {
    fetchFiles();

    return () => {
      cleanup();
    };
  }, []);

  const fetchFiles = async () => {
    try {
      setIsLoading(true);

      //

      let res = await axios.get(`${files_api.sharedFiles}/${id}`);

      if (res.data && res.data.file) {
        localStorage.setItem("loggedIn", "false");
        localStorage.removeItem("isBackTranslation");
        localStorage.removeItem("checkPageMsg");
        await removeUserDetailsFromLocalStorage();

        console.log(res.data.file);
        setSharedFiles(res.data.file.files);

        let receiver = res.data.file.receiver
 
        localStorage.setItem("email", receiver.email); 
        localStorage.setItem("firstName", receiver.firstName); 
        localStorage.setItem("lastName", receiver.lastName);
        localStorage.setItem("role", receiver.role); 
        localStorage.setItem("id", receiver._id);
      }
    } catch (error) {
        console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const cleanup = () => {
    try {
    } catch (error) {}
  };

  const toggleComments = (data, id) => {
    if (showComments == true && commnetObjId == id) {
      setShowComments(false);
    } else if (showComments == true) {
      setCommentData(data);
      setCommnetObjId(id);
    } else {
      setShowComments(true);
      setCommentData(data);
      setCommnetObjId(id);
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "name",
      render: (value, record) => (
        <>
          <FilenameComponent file={record} />
        </>
      ),
    },
    {
      title: (
        <span className={`flex items-center justify-end mr-4-5 w-full`}>
          Action
        </span>
      ),
      dataIndex: "",
      key: "x",
      render: (item, record) => (
        <div className="flex items-center justify-end mr-10">
          <p>
            {" "}
            {record && record.fileType && record.fileType == "audio" ? (
              <AudioPlayer
                revokeOtherFunctions={null}
                data={record}
                audioType={record.fileType}
                isDisabled={isTableLoading}
                isReverse={false}
                reload={fetchFiles}
              />
            ) : null}
          </p>
          <p className="flex items-center justify-end mr-10">
            {record && record.fileType && record.fileType == "script" ? (
              <DraftScriptModal data={record} isDisabled={isLoading} />
            ) : null}
          </p>
          <p className="flex items-center justify-end mr-10">
            {" "}
            {record && record.fileType && record.fileType == "video" ? (
              <VideoPlayer data={record} fileType="DRAFTFILE" />
            ) : null}
          </p>

          <>
            <Tooltip placement="topLeft" title="Comment">
              <Button
                className="ml-2"
                disabled={isPlayingStatus}
                onClick={(e) => {
                  // setShowComments(!showComments);
                  toggleComments(record, record.fileName);
                }}
              >
                {showComments == true && commnetObjId == record.fileName ? (
                  <FaCommentSlash size={16} />
                ) : (
                  <FaComment size={16} />
                )}
              </Button>
            </Tooltip>
          </>
        </div>
      ),
    },
  ];

  return (
    <div className="layout">
      <Layout>
        <Layout.Header>
          <HeaderUIComponent />
        </Layout.Header>
        <Layout>
          <>
            <Layout.Content>
              <div className="sharedFilesPage">
                {isLoading == true ? (
                  <SpinnerComponent />
                ) : (
                  <div className="mt-4">
                    <h4>Shared Files</h4>
                    <Table
                      loading={{
                        spinning: isTableLoading,
                        indicator: (
                          <span>
                            <SmallSpinnerComponent />
                          </span>
                        ),
                      }}
                      pagination={{ pageSize: 5 }}
                      columns={columns}
                      dataSource={sharedFiles}
                    />

                    <div className="pb-2 mx-2">
                      {showComments && (
                        <CommentsComponent chunk={commentData} />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Layout.Content>
          </>
        </Layout>
        <Layout.Footer>
          <FooterComponent />
        </Layout.Footer>
      </Layout>
    </div>
  );
};

export default SharedFiles;
