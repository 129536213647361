import { Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import SigninPage from "./pages/auth/signin/signin.page";
import { ToastContainer } from "react-toastify";
import SignUpPage from "./pages/auth/signup/signup.page";
import ForgotPasswordPage from "./pages/auth/forgot-password/forgot-password.page";
import DashboardPage from "./pages/dashboard/dashboard.page";
import DraftPage from "./pages/draft/draft.page";
import DraftDetails from "./pages/draft/draftDetails/draft.details";
import MasterRecordingPage from "./pages/master-recording";
import CheckPage from "./pages/check-page";
import CitConCheckPage from "./pages/cit-con-check";
import ProjectPage from "./pages/my-project/my-project.page";
import ProjecDetails from "./pages/my-project/project.details";

import MyProgressPage from "./pages/my-progress/my-progress.page";
import TestPage from "./pages/test/test.page";
import { useEffect } from "react";
import TeamConnectPage from "./pages/team-connect/team-connect.page";
import ToDoPage from "./pages/to-do/to-do.page";
import SupportPage from "./pages/support/support.page";
import RecentUpdatesPage from "./pages/recent-updates/recent-updates.page";
import "./index.scss";
import PrivateRoute from "./services/PrivateRoute";
import AudioPlayListPage from "./pages/audio-playlist/index";
import VideoPlaylist from "./pages/video-playlist/video.page";
// import DraftAudioComponent from "./components/draft-page-component/draft-audio";


// import ChatbotPage from "./pages/chatbot-page";
import ScriptPage from "./pages/script-list/script.page";
import Home from "./pages/home/home";
import SharedFiles from "./pages/shared-files/shared-files.page";

const App = () => {
  let logIn = localStorage.getItem("loggedIn");
  useEffect(() => {
    document.cookie = "app_version=1.0.0;path=/";
    const osType = getOSType();
    localStorage.setItem('osType',osType)
  }, []);

  const getOSType = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Windows')) {
      return 'Windows';
    } else if (userAgent.includes('Macintosh')) {
      return 'MacOS';
    } else if (userAgent.includes('Linux')) {
      return 'Linux';
    } else if (userAgent.includes('Android')) {
      return 'Android';
    } else if (userAgent.includes('iOS')) {
      return 'iOS';
    } else {
      return 'Unknown';
    }
  };

  return (
    <>
      <ToastContainer />

      <Routes>
        <Route exact path="/test" element={<TestPage />} />

        {/* <Route exact path="/" element={<DashboardPage />} /> */}

        <Route
          exact
          path="/"
          element={logIn === "true" ? <DashboardPage /> : <Home/>}
        />

        <Route exact path="/signin" element={<SigninPage />} />
        <Route exact path="/signup" element={<SignUpPage />} />
        <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route exact path="/shared-files/:id" element={<SharedFiles />} />

        <Route element={<PrivateRoute />}>
          <Route exact path="/dashboard" element={<DashboardPage />} />
          <Route exact path="/draft" element={<DraftPage />} />
          <Route exact path="/draft-details" element={<DraftDetails />} />
          <Route exact path="/cross-check" element={<CheckPage />} />
          <Route exact path="/cit-con-check" element={<CitConCheckPage />} />
          <Route exact path="/master-recording" element={<MasterRecordingPage />}/>

          <Route exact path="/myProject" element={<ProjectPage />} />

          <Route exact path="/project-details" element={<ProjecDetails />} />
          <Route exact path="/my-progress" element={<MyProgressPage />} />
          <Route exact path="/team-connect" element={<TeamConnectPage />} />
          <Route exact path="/to-do" element={<ToDoPage />} />
          <Route exact path="/support" element={<SupportPage />} />
          <Route exact path="/recent-updates" element={<RecentUpdatesPage />} />
          <Route exact path="/audio-playlist" element={<AudioPlayListPage />} />
          <Route exact path="/video-player" element={<VideoPlaylist />} />
          <Route exact path="/script-view" element={<ScriptPage />} />
          {/* 
          <Route exact path="/chatbot" element={<ChatbotPage />} />          
          <Route exact path="/draft-audio" element={<DraftAudioComponent />} /> */}

        </Route>
      </Routes>
    </>
  );
};

export default App;
